<template>
  <div class="tower-cameras">
    <div class="title-box">
      <div class="title-box__icon">
        <img src="../../../assets/icons/dashboard/Tower Cameras.png" />
        <span>Tower Cameras</span>
      </div>

      <el-button
        type="info"
        @click="$router.push({ path: '/data', query: { tabsIndex: 2, time } })"
      >
        Show More
      </el-button>
    </div>

    <div class="tower-cameras__list">
      <template v-if="detail.latelyPhotoList && detail.latelyPhotoList.length">
        <div
          class="tower-cameras__list__item"
          v-for="(item, index) in detail.latelyPhotoList || []"
          :key="index"
        >
          <span class="el-icon-camera-solid _font-size__sub__heading">{{ item.camera }}</span>
          <el-image
            :preview-src-list="detail.latelyPhotoList.map((item) => item.filePath)"
            :z-index="index"
            :src="item.filePath"
            fit="cover"
          />
        </div>
      </template>

      <LoadingData v-else :loading="loading"></LoadingData>
    </div>
  </div>
</template>

<script>
import { props } from './props'

export default {
  props,
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.tower-cameras {
  &__list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 17px;
    height: calc(100% - 36px - 20px);
    &__item {
      > span {
        display: inline-block;
        margin-bottom: 15px;
        font-weight: 600;

        &::before {
          margin-right: 15px;
        }
      }

      > .el-image {
        height: calc(100% - 14px - 15px);
        border-radius: 10px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
</style>
